.social-link {
  width: 17em;
  height: 5em;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 0px;
  -ms-grid-columns: 1fr 0.25fr;
  grid-template-columns: 1fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.6);
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  overflow: hidden;
}

.social-link a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.social-link img {
  width: 60%;
}

.social-link::before {
  content: "";
  z-index: -1;
  height: 4px;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.appleMusic:before {
  background: linear-gradient(
    90deg,
    rgba(18, 194, 233, 1) 0%,
    rgba(196, 113, 237, 1) 84%,
    rgba(246, 79, 89, 1) 100%
  );
}

.spotify:before {
  background: #1ed660;
}

.youtube:before {
  background: red;
}

.social-link:hover:before,
.social-link:focus:before,
.social-link:active:before {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .social-link {
    width: 65%;
    height: 4em;
  }
}
