* {
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
}

.cookie-banner {
  animation: banner-fade-up 4s ease-in-out;
  align-items: center;
}

.cookie-banner-text {
  flex: 1 0 0px !important;
  font-size: 12px;
}

@keyframes banner-fade-up {
  0% {
    transform: translateY(1000px);
  }
  50% {
    transform: translateY(1000px);
  }
  100% {
    transform: translateY(0px);
  }
}

.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: black; */
  background: white;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  /* animation: fade-up 2s ease-in-out; */
  animation: fade-forward 1.8s ease-in-out;
  position: absolute;
  visibility: hidden;
  z-index: -1;
  overflow: hidden;
}

.intro-logo {
  width: 3em;
  height: auto;
  animation: logo-reaveal 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@keyframes logo-reaveal {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade-up {
  0% {
    visibility: visible;
    opacity: 100;
    z-index: 10;
    transform: translateY(0);
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-200px);
    z-index: 10;
  }
}

@keyframes fade-forward {
  0% {
    visibility: visible;
    opacity: 100;
    z-index: 10;
    transform: scale(1);
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
    transform: scale(50);
    z-index: 10;
  }
}

.section {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

#page-one {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

#page-one:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #fff url("../../images/CoverArt.jpeg") center fixed no-repeat;
  background-size: cover;
  filter: blur(10px);
  transform: scale(1.1);
}

.content {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  animation: content-fade-in 2s cubic-bezier(0.68, -0.55, 0.27, 1.2);
  /* animation: content-fade-in 1.8s cubic-bezier(.68,-0.55,.27,1.43); */
  /* animation: content-fade-in 2s cubic-bezier(0.165,0.84,0.44,1); */
  /* animation: content-fade-in 1.8s cubic-bezier(0.770, 0.000, 0.175, 1.000); */
  /* animation: content-fade-in 1s 0.6s ease-out; */
}

@keyframes content-fade-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(100%);
  }
}

.heading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  display: block;
  margin-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-weight: 700;
  text-shadow: 0 2px 9px #3a3939;
  font-size: 2em;
}

.artist {
  display: block;
  margin-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-weight: 400;
  text-shadow: 0 2px 9px #3a3939;
  font-size: 1.5em;
  margin-bottom: 0.7em;
}

.album {
  text-transform: uppercase;
  margin-bottom: 0.3em;
  font-size: 0.8em;
  font-weight: 600;
}

.middle {
  display: flex;
  align-items: center;
}

.cover {
  width: 300px;
  height: auto;
  border: 5px solid white;
  margin-right: 10px;
}

.link div:last-child {
  margin-bottom: 0px;
}

.logo {
  width: 25px;
  /* height: auto; */
  /* height: 40px; */
}

.learn-more {
  color: #b76529;
  opacity: 0.7;
}

footer {
  position: fixed;
  bottom: 0;
  padding: 10px;
  color: white;
  opacity: 0.7;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin: 0;
  box-sizing: border-box;
}

footer a {
  text-decoration: none;
  font-weight: 700;
  color: white;
}

@media screen and (max-width: 690px) {
  #page-one {
    position: absolute;
    justify-content: flex-start;
    flex-direction: column;
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .cover {
    margin-bottom: 10px;
    margin-right: 0px;
    width: 250px;
    height: auto;
  }

  .middle {
    flex-direction: column;
  }

  .content {
    height: 100%;
    justify-content: start;
    transform: scale(0.9);
  }

  .heading {
    font-size: 90%;
  }

  .logo {
    margin-top: 20px;
  }

  footer {
    font-size: 10px;
    position: relative;
  }
}
